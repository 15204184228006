<template>
    <div>
        <Header />
        <div class="wrapper">
            <div class="main">
                <div class="row menu-margins" style="min-height: 30em;">
                    <div class="col-12 text-center">
                        <h1 class="text-center caligraphy pt-5">404 Not Found</h1>
                        <h1 class="text-center caligraphy">We do not appear to have a recipe for this page.</h1>
                        <h2 class="text-center caligraphy"><a class="btn" href="/">Go back</a></h2>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    </div>
</template>

<script>
export default {
    name: 'notFound'
}
</script>