import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import VueAnalytics from 'vue-analytics';

import router from './router.js';

// Global Components
import Header from './components/Header';
import Footer from './components/Footer';
import HeaderBanner from './components/HeaderBanner';
import SpecialOverlay from './components/SpecialOverlay';

Vue.use(VueRouter);
Vue.use(VueAnalytics, {
  id: 'G-D34JLQSV5K',
  router
});

Vue.component('Header', Header);
Vue.component('Footer', Footer);
Vue.component('HeaderBanner', HeaderBanner);
Vue.component('SpecialOverlay', SpecialOverlay);

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App),
}).$mount('#app');
