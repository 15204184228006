<template>
    <div>
        <Header />
        <div class="wrapper">
            <div class="main">
                <div class="container">
                    <h2 class="mt-5 mb-2 text-center">Reach out to us!</h2>
                    <p class="mb-5 text-center">If you have questions or concerns<br>feel free to contact.</p>
                    <div class="row m-lg-5 img-responsive contact-general-info">
                    <div class="col-lg-4 mt-3 mb-3">
                        <h3 class="mb-2">Contact Info</h3>
                        <p>
                            <b>Address:</b><br><a href="https://www.google.com/maps/place/Kosmos/@42.1466592,-71.2542971,17z/data=!4m5!3m4!1s0x89e47c3aa8d00001:0xba632c5ec5927c25!8m2!3d42.146694!4d-71.2521832" target="_blank">944 Main St, MA 02081</a><br>
                            <br><b>Email:</b><br><a href="mailto:info@kosmoswma.com">info@kosmoswma.com</a><br>
                            <br><b>Phone:</b><br><a href="tel:5089213086">(508) 921 3086</a>
                        </p>

                        <h3 class="mb-0 mt-5">Hours of Operation</h3>
                        <table class="table hours-table table-borderless">
                            <tbody>
                                <tr>
                                    <td>Monday</td>
                                    <td>Closed</td>
                                </tr>
                                <tr>
                                    <td>Tuesday - Thursday</td>
                                    <td>4pm - 10pm</td>
                                </tr>
                                <tr>
                                    <td>Friday</td>
                                    <td>4pm - 11pm</td>
                                </tr>
                                <tr>
                                    <td>Saturday</td>
                                    <td>12pm - 11pm</td>
                                </tr>
                                <tr>
                                    <td>Sunday</td>
                                    <td>12pm - 10pm</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="col-lg-1"></div>
                    <div class="col-lg-6">
                            <div class="card">
                                <div class="card-body">
                                    <form action="https://formspree.io/f/mjvzyjwp" method="POST">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Email address</label>
                                            <input type="email" name="_replyto" class="form-control custom-form-input" aria-describedby="emailHelp" placeholder="Enter email">
                                        </div>
                                        <div class="form-group">
                                            <label for="exampleInputPassword1">Name</label>
                                            <input type="text" name="name" class="form-control custom-form-input" id="exampleInputPassword1" placeholder="Name">
                                        </div>

                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Your Message</label>
                                            <textarea class="form-control custom-form-input" name="message" style="height: 400px;" id="exampleFormControlTextarea1"></textarea>
                                        </div>
                                        <button type="submit" class="btn form-btn">Submit</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    </div>
</template>

<script>
export default {
    name: 'Contact'
}
</script>