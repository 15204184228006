import VueRouter from 'vue-router';

// Import all pages
import Home from './pages/Home';
import Contact from './pages/Contact';
import Menu from './pages/Menu';
import NotFound from './pages/errors/NotFound';

const router = new VueRouter({
 
  routes: [
    {
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/contact',
		name: 'Contact',
		component: Contact
	},
	{
		path: '/menu/:brunch?',
		name: 'Menu',
		component: Menu
	},
	{
		path: '*',
		name: 'notFound',
		component: NotFound
	}
  ],
  mode: 'history'
});

// Factory to handle future middleware functionality
function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];

    if (!subsequentMiddleware) {
		return context.next;
    } 
  
    return (...parameters) => {
		context.next(...parameters);
		const nextMiddleware = nextFactory(context, middleware, index + 1);
		subsequentMiddleware({ ...context, next: nextMiddleware });
    };
}
  
router.beforeEach((to, from, next) => {
	if (to.meta.middleware) {
		const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware];

		const context = {
			from,
			next,
			router,
			to,
		};
		const nextMiddleware = nextFactory(context, middleware, 1);

		return middleware[0]({ ...context, next: nextMiddleware });
	}

	return next();
});

export default router;