<template>
    <div class="text-center custom-banner">
        <!-- <h6 class="banner-text mb-0"><a class="text-light" href="/menu/happy-hour">Check out our Happy Hour Menu!</a></h6> -->
        <!-- <h6 class="banner-text mb-0"><a class="text-light" href="menu/brunch">Check out our New Brunch Menu!</a></h6> -->
        <h6 class="banner-text mb-0">Join Us for Dinner on Thanksgiving from 12-8pm</h6>
    </div>
</template>

<script>
export default {
    name: 'HeaderBanner'
}
</script>