<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
  import './stylesheets/app.css';

  export default {
    name: 'app'
  }
</script>

