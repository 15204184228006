
<template>
    <div class="container-fluid">
        <div class="row border-top">
            <div class="col-12">
                <h1 class="mt-5 mb-0 text-center caligraphy">Lunch Additions</h1>
                <p class="mt-1 mb-4">enjoy our lunch additions on top of our regular menu every<br><b>Saturday and Sunday from 12pm-3pm</b></p>
            </div>
            <div class="col-lg-2"></div>
            <div class="col-lg-8 mt-3">
                <h3 class="mb-3 text-center caligraphy">· Salad Add-On's ·</h3>
                <p class="text-center"><b>Seared Shrimp</b> 11</p>
                <p class="text-center"><b>Falafel</b> 9</p>
                <p class="text-center"><b>Charred Octopus</b> 13</p>
                <p class="text-center"><b>Grilled Chicken</b> 9</p>
                <p class="text-center"><b>Salmon</b> 14</p>

                <h3 class="mb-0 mt-5 text-center caligraphy">· Handhelds ·</h3>
                <p class="mt-0 mb-4">served with fries or a sub a side salad for $3</p>

                <p class="text-center"><b>Skepasti Kotopoulo</b><br>
                    grilled pita sandwich with Sliced Marinated chicken, melted kasseri cheese, tomatoes and Honey mustard sauce 19</p>

                <p class="text-center"><b>Falafel</b><br>
                    house made, avocado spread, mix greens, tomato, sliced cucumbers, Tahini sauce, wrapped in pita bread 17</p>

                <p class="text-center"><b>Skepasti Xoirino</b><br>
                    grilled pita sandwich Marinated sliced pork tomatoes, melted Kasseri cheese and tzatziki sauce 19</p>

                <p class="text-center"><b>Lamb Burger</b><br>
                    ground lamb, tzatziki, caramelized onions, tomato, lettuce, feta cheese on a potato roll 21</p>
                
                <p class="text-center"><b>Garida Pita</b><br>
                    grilled shrimp, lemon yogurt, mix greens, crispy onions wrapped in pita 20</p>
            </div>
            <div class="col-lg-2"></div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'DinnerMenu'
}
</script>