<template>
    <div id="#footer">
        <div class="section section-download" data-background-color="gray">
            <div class="container-liquid">
                <div class="row justify-content-md-center text-center">
                    <div class="col-lg-3 text-center" >
                        <h3>Hours</h3>
                        <table class="table mx-auto text-black hours-table table-borderless">
                            <tbody>
                                <tr>
                                    <td>Monday</td>
                                    <td>Closed</td>
                                </tr>
                                <tr>
                                    <td>Tuesday - Thursday</td>
                                    <td>4pm - 10pm</td>
                                </tr>
                                <tr>
                                    <td>Friday</td>
                                    <td>4pm - 11pm</td>
                                </tr>
                                <tr>
                                    <td>Saturday</td>
                                    <td>12pm - 11pm</td>
                                </tr>
                                <tr>
                                    <td>Sunday</td>
                                    <td>12pm - 10pm</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    
                    <div class="col-lg-3 text-center">
                        <h3>Contact</h3>
                        <p><b>Address:</b><br><a href="https://www.google.com/maps/place/Kosmos/@42.1466592,-71.2542971,17z/data=!4m5!3m4!1s0x89e47c3aa8d00001:0xba632c5ec5927c25!8m2!3d42.146694!4d-71.2521832" target="_blank">944 Main St<br>Walpole, MA 02081</a></p>
                        <p><b>Email:</b><br><a href="mailto:info@kosmoswma.com">info@kosmoswma.com</a></p>
                        <p><b>Phone:</b><br><a href="tel:5089213086">(508) 921 3086</a></p>
                    </div>

                    <div class="col-lg-3 text-center">
                        <a target="_blank" href="https://www.toasttab.com/kosmos-restaurant-944-main-street/giftcards">
                            <img style="width: 55%" src="../assets/gc-logo.png"/>
                        </a>
                        <h3 class="mb-0 mt-4">Follow Us</h3>
                        <a target="_blank" href="https://instagram.com/kosmos_ma?igshid=YzA2ZDJiZGQ=" class="btn btn-neutral btn-icon btn-linkedin btn-lg btn-round" rel="tooltip" title="Follow us">
                            <i class="fab fa-instagram"></i>
                        </a>
                        <a target="_blank" href="https://www.facebook.com/kosmoswalpole/" class="btn btn-neutral btn-icon btn-facebook btn-round btn-lg" rel="tooltip" title="Like us">
                            <i class="fab fa-facebook-square"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <footer class="footer" data-background-color="gray">
            <div class="container">
                <div class="copyright" id="copyright">
                &copy; {{ new Date().getFullYear() }} All rights reserved Kosmos.<br>Developed & Maintained by
                <a href="https://fifty6studios.com" target="_blank">Fifty6 Studios</a>.
                </div>
            </div>
        </footer>
    </div>
</template>

<script>

export default {
    name: 'Footer'
}
</script>